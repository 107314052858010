<template>
  <div>
    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
      <tr>
        <th>

        </th>
        <th>
          Banco
        </th>
        <th></th>
        <th>
          F. Aplicación
        </th>
        <th>
          Importe
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, key) in items" :key="key">
        <td>
          <b-button @click="$emit('drop-item', key)"><b-icon
              icon="window-close"
              size="is-small">
          </b-icon></b-button>
        </td>
        <td>
          {{item.account.id}}
        </td>
        <td>
          {{item.account.bank}}
        </td>
        <td>
          <b-input type="date" v-model="item.date"></b-input>
        </td>
        <td>
          <b-input type="number" v-model="item.amount"></b-input>
        </td>
      </tr>
      <template v-for="item in index">
        <tr class="tr__empty">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ total | currency }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "DepositTable",
  props: {
    items: Array,
    total: Number,
  },
  data() {
    return {
      limit: 4,
    }
  },
  computed: {
    index() {
      const items = [];
      for (let i = this.items.length; i < this.limit; i++) {
        items.push(i);
      }
      return items;
    },
  },
  methods: {

  }
}
</script>

<style scoped>
.tr__empty {
  height: 28px;
}
</style>
