<template>
  <div>
    <div class="columns is-multiline">
      <div class="column">
        <b-field label="Efectivo">
          <b-select v-model="selected_account" @input="onChangeAccountEfectivo">
            <option v-for="item in cash_accounts"
                    :value="item">
              {{ item.bank }} &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp; {{ item.account_number }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="Tarjeta">
          <b-select v-model="selected_account" @input="onChangeAccountTarjeta">
            <option v-for="item in card_accounts"
                    :value="item">
              {{ item.bank }} &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp; {{ item.account_number }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <form @submit.prevent="onSubmit">
          <b-button native-type="submit" class="is-primary">Guardar</b-button>
        </form>
      </div>
    </div>
    <div>
      <DepositTable
          :items="items"
          :total="amount_total"
          @drop-item="onDropItem"
      />
    </div>
    <br>
    <div class="columns is-multiline">
      <div class="column">
        <b-field label="Efectivo">
          <b-input :value="this.$options.filters.currency(this.cash_total)" disabled="true"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Tarjeta">
          <b-input :value="this.$options.filters.currency(card_total)" disabled="true"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Falta">
          <b-input :value="this.$options.filters.currency(total_pendiente)" disabled="true"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Total">
          <b-input :value="this.$options.filters.currency(total)" disabled="true"></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import DepositTable from "@/components/cash-closing/DepositTable";

export default {
  name: "DepositSection",
  components: {DepositTable},
  props: {
    from_date: Date,
    branch_office: String | Number
  },
  data() {
    return {
      accounts: [],
      selected_account: null,
      items: [],
      total: 0,
      total_efectivo: 0
    }
  },
  computed: {
    cash_accounts: function () {
      return this.accounts.filter((item) => {
        return item.type.id === 1 && this.items.filter((i) => {
          return i.account.id === item.id
        }).length === 0
      });
    },
    card_accounts: function () {
      return this.accounts.filter((item) => {
        return item.type.id === 2 && this.items.filter((i) => {
          return i.account.id === item.id
        }).length === 0
      });
    },
    amount_total: function () {
      let total = 0;
      this.items.map(item => {
        total += Number.parseFloat(item.amount);
      });
      return total;
    },
    card_total: function () {
      let total = 0;
      this.items.filter(i => {
        return i.account.type.id === 2;
      }).map(item => {
        total += Number.parseFloat(item.amount);
      });
      return total;
    },
    cash_total: function () {
      let total = 0;
      const items = this.items.filter((i) => {
        return i.account.type.id === 1;
      });
      items.map(item => {
        total += Number.parseFloat(item.amount);
      });
      return total;
    },
    total_pendiente: function () {
      return this.total - (this.cash_total + this.card_total);
    },
    items_to_send: function () {
      return {
        deposits: this.items.map((item) => {
          return {
            bank_account_id: item.account.id,
            apply_date: item.date,
            amount: item.amount,
            type: item.account.type.id
          };
        })
      }
    }
  },
  mounted() {
    this.prepareData();
  },
  methods: {
    async prepareData() {
      this.$loader.show();
      await this.$http.get('/bank-accounts').then(({data}) => {
        this.accounts = data.data;
      }).catch((errors) => {
        console.error(errors);
        this.$toast.error(errors);
      });

      const d = this.$options.filters.date(this.from_date, 'YYYY-MM-DD');

      await this.$http.get(`/branch-offices/${this.branch_office}?deposits&deposit_date=${d}`)
          .then(({data}) => {
            this.items = data.data.deposits;
            this.total = data.data.total_sells;
            this.total_efectivo = Number.parseFloat(data.data.total_efectivo_registrado.toString());
          }).catch(error => {
            console.error(error);
          });

      this.$loader.hide();
    },
    onChangeAccountEfectivo(value) {
      this.onChangeAccount('efectivo', value)
    },
    onChangeAccountTarjeta(value) {
      this.onChangeAccount('tarjeta', value)
    },
    onChangeAccount(tipo, value) {
      if (value) {
        console.log(tipo)
        console.log(value)
        let amount = 0;
        switch (tipo){
          case 'efectivo':
            amount = this.total_efectivo - this.cash_total;
            break;
          default:
            amount = this.total - this.card_total - this.total_efectivo;
            break;
        }
        amount = amount.toFixed(2);
        amount = amount !== '-0.00' ? amount: '0.00';
        this.selected_account = '';
        const item = {
          account: value,
          date: this.$options.filters.date(this.from_date, 'YYYY-MM-DD'),
          amount
        }
        this.items.push(item);
        this.items = this.items.slice(0);
      }
    },
    onDropItem(index) {
      const item = this.items[index];
      this.items.splice(index, 1);
      this.items = this.items.slice(0);
      if (item.id) {
        this.deleteItem(item);
      }
    },
    deleteItem(item) {
      this.$loader.show();
      this.$http.delete(`/branch-office-deposits/${item.id}`)
      .then(({data}) => {
        this.$toast.success(data.message);
      }).catch(error => {
        console.error(error);
        this.$toast.error(error);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    onSubmit() {
      this.$loader.show();
      const d = this.$options.filters.date(this.from_date, 'YYYY-MM-DD');
      this.$http.post(`/branch-office-deposits/${this.branch_office}/${d}`, this.items_to_send)
          .then(({data}) => {
            this.$toast.success(data.message);
            this.items = data.deposits;
          }).catch(errors => {
            console.error(errors);
            this.$toast.error(errors)
          }).finally(() => {
            this.$loader.hide();
          });
    }
  }
}
</script>

<style scoped>

</style>
