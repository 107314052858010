<template>
  <div class="block">
    <b-field grouped>
      <b-field>
        <div class="block">
          <b-radio v-model="params.tax"
                   v-for="item in typeTaxes"
                   name="tax"
                   :native-value="item.value"
                   @input="prepareComponent"
                   :key="item.value">
            {{ item.text }}
          </b-radio>
        </div>
      </b-field>
      <b-field>
        <div class="block">
          <b-radio v-model="params.type"
                   v-for="item in typeInvoices"
                   name="type"
                   :native-value="item.value"
                   :key="item.value"
                   @input="prepareComponent"
          >
            {{ item.text }}
          </b-radio>
        </div>
      </b-field>
      <b-field>
        <button class="button is-info" type="button" @click="prepareComponent">
          <b-icon icon="sync-alt" :custom-class="loading ? 'fa-spin' : ''"></b-icon>
        </button>
      </b-field>
      <b-field>
        <button class="button is-danger" type="button" @click="showModalOrders=true">
          <b-tooltip :label="params.type === 'no_billed' ? 'Reporte de Ordenes por Facturara': 'Reporte de Ordenes Facturadas'" >
            <b-icon icon="file"></b-icon>
          </b-tooltip>

        </button>
      </b-field>
    </b-field>
    <div class="columns">
      <div class="column is-6" v-if="params.type === 'no_billed'">
        <b-field>
          <b-button
              @click="onGeneralInvoice"
              class="button is-primary"
              expanded>
            Generar Factura Público en General
          </b-button>
        </b-field>
      </div>
      <div class="column is-6" v-if="params.type === 'no_billed'">
        <b-field>
          <b-button
              @click="onSyncGeneralInvoice"
              class="button is-info"
              expanded>
            Ejecutar proceso de facturas pasadas
          </b-button>
        </b-field>
      </div>
      <div class="column is-6" v-if="params.type === 'billed'">
        <b-field>
          <b-button
              v-if="false"
              @click="onCreditNote"
              class="button is-primary"
              expanded>
            Generar nota de Crédito
          </b-button>
          <b-button
              @click="onCancel"
              class="button is-primary"
              expanded>
            Cancelar Factura Público en General
          </b-button>
        </b-field>
      </div>
    </div>
    <b-table
        sticky-header
        :loading="loading"
        :data="data_items"
        :row-class="() => {return 'tr__empty'}"
    >
      <b-table-column label="Orden" field="no" v-slot="props">
        {{ props.row.no }}
      </b-table-column>
      <b-table-column label="Número local" field="no_local" v-slot="props">
        {{ props.row.no_local }}
      </b-table-column>
      <b-table-column label="Paciente" field="patient" v-slot="props">
        {{ props.row.patient }}
      </b-table-column>
      <b-table-column label="Subtotal" field="subtotal" type="numeric" v-slot="props">
        {{ props.row.subtotal }}
      </b-table-column>

      <b-table-column label="IVA" field="iva" type="numeric" v-slot="props">
        {{ props.row.iva }}
      </b-table-column>

      <b-table-column label="Total" field="total" type="numeric" v-slot="props">
        {{ props.row.total}}
      </b-table-column>

      <template slot="footer">
        <th class="is-hidden-mobile" colspan="4">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap">Total</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap">{{ total | currency}}</div>
        </th>
      </template>

    </b-table>
    <b-field grouped>
      <h2>Con IVA</h2>
      <b-field label="Serie">
        <b-input v-model="invoiceData.serial" disabled></b-input>
      </b-field>
      <b-field label="Factura">
        <b-input v-model="invoiceData.invoice" disabled></b-input>
      </b-field>
      <b-field label="Concepto">
        <b-input v-model="invoiceData.concept" disabled></b-input>
      </b-field>
      <b-field v-if="invoiceData.invoice">
        <b-button
            :label="`PDF `"
            icon-left="eye"
            @click="() => {this.showModal = true}" />
      </b-field>
    </b-field>

    <b-field grouped>
      <h2>Sin IVA</h2>
      <b-field label="Serie">
        <b-input v-model="invoiceData.serial" disabled></b-input>
      </b-field>
      <b-field label="Factura">
        <b-input v-model="invoiceData2.invoice" disabled></b-input>
      </b-field>
      <b-field label="Concepto">
        <b-input v-model="invoiceData2.concept" disabled></b-input>
      </b-field>
      <b-field v-if="invoiceData2.invoice">
        <b-button
            :label="`PDF `"
            icon-left="eye"
            @click="() => {this.showModal2 = true}" />
      </b-field>
    </b-field>

    <b-modal v-model="showModal">
      <div>
        <iframe :src="`${this.invoiceData.url}`" frameborder="0" class="lapi-iframe"/>
      </div>
    </b-modal>

    <b-modal v-model="showModal2">
      <div>
        <iframe :src="`${this.invoiceData2.url}`" frameborder="0" class="lapi-iframe"/>
      </div>
    </b-modal>

    <b-modal v-model="showModalOrders">
      <iframe class="lapi-iframe" :src="reportUrl"></iframe>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "TableInvoiceGeneral",
  props: {
    parentParams: {},
  },
  data() {
    return {
      typeTaxes: [
        {text: 'Con IVA', value: 'iva'},
        {text: 'Sin IVA', value: 'no_iva'},
      ],
      typeInvoices: [
        {text: 'Por facturar', value: 'no_billed'},
        {text: 'Facturado', value: 'billed'},
      ],
      params: {
        tax: 'iva',
        type: 'no_billed'
      },
      loading: false,
      data: {
        orders: {
          detail: []
        }
      },
      invoiceData: {
        serial: ''
      },
      invoiceData2: {
        serial: ''
      },
      limit: 4,
      showModal: false,
      showModal2: false,
      showModalOrders: false,
      in_process: false
    }
  },
  computed: {
    branchOffice() {
      return this.$store.state.main.activeBranchOffice || {};
    },
    total() {
      let total = 0;
      this.data_items.map((item) => {
        if(item.total){
          total+=Number.parseFloat(item.total);
        }
      })
      return total;
    },
    data_items() {
      const data = this.data.orders.detail.splice(0);
      for(let i=data.length; i < this.limit; i++){
        data.push({});
      }
      return data;
    },
    reportUrl() {
      const d = this.$options.filters.date(this.parentParams.from_date, 'YYYYMMDD');
      const  url = new URL(`${process.env.VUE_APP_API_URL}/cash-desk-closing/report/${this.parentParams.branch_office}/${d}`);
      for(const i in this.params){
        url.searchParams.append(i, this.params[i]);
      }
      return url.href;
    }
  },
  mounted() {
    this.invoiceData.serial = this.branchOffice.serial;
    //this.invoiceData.concept = `${this.parentParams.from_date}`
    this.invoiceData.concept = '';
    this.invoiceData2.serial = this.branchOffice.serial;
    //this.invoiceData.concept = `${this.parentParams.from_date}`
    this.invoiceData2.concept = '';
    this.prepareComponent();
  },
  methods: {
    async prepareComponent() {
      this.loading = true;
      this.data = {orders: {detail:[]}};
      let params = {
        ...this.paramsFix(),
        ...this.params,
      }
      await this.$http.get('cash-desk-closing/show-orders', {
        params
      }).then(({data}) => {
        this.data = data.data || {};
        if(this.data.invoice){
          this.invoiceData.invoice = this.data.invoice.folio;
          this.invoiceData.concept = this.data.invoice.concept;
          this.invoiceData.url = this.data.invoice.url;
        }else{
          this.invoiceData.invoice = '';
          this.invoiceData.concept = '';
        }
        if(this.data.invoice_0tax){
          this.invoiceData2.invoice = this.data.invoice_0tax.folio;
          this.invoiceData2.concept = this.data.invoice_0tax.concept;
          this.invoiceData2.url = this.data.invoice_0tax.url;
        }else{
          this.invoiceData2.invoice = '';
          this.invoiceData2.concept = '';
        }

      }).catch(errors => {
        this.$toast.error('Sin Datos Registrados');
      }).finally(() => {
        this.loading = false;
      });
    },
    onGeneralInvoice(){
      if(!this.in_process) {
        this.in_process = true;
        this.$loader.show();
        const d = this.$options.filters.date(this.parentParams.from_date, 'YYYY-MM-DD');
        this.$http.post(`/general-invoice/${this.branchOffice.id}/${d}/${this.params.tax}`)
            .then(({data}) => {
              this.$toast.success(data.message);
              this.invoiceData.invoice = data.invoice.folio;
              this.invoiceData2.invoice = data.invoice_0tax.folio;
            }).catch(({response}) => {
          console.error((response.data.message))
          this.$toast.error(response.data.message);
        }).finally(() => {
          this.$loader.hide();
          this.in_process = false;
          this.prepareComponent();
        })
      }
    },
    onSyncGeneralInvoice() {
      if(!this.in_process) {
        this.in_process = true;
        this.$loader.show();
        this.$http.post(`/general-invoice/${this.branchOffice.id}/run_process`)
            .then(({data}) => {
              if(data.ask){
                this.$buefy.dialog.confirm({
                  message: data.message,
                  confirmText: 'Si volver a ejecutar',
                  onConfirm: () => {
                    this.$http.post(`/general-invoice/${this.branchOffice.id}/run_process`, {'force': true})
                        .then(({data}) => {
                          this.$buefy.dialog.alert({
                            message: data.message
                          });
                        });
                  }
                });
              }else{
                this.$buefy.dialog.alert({
                  message: data.message
                });
              }
            }).catch(({response}) => {
          console.error((response.data.message))
          this.$toast.error(response.data.message);
        }).finally(() => {
          this.$loader.hide();
          this.in_process = false;
          this.prepareComponent();
        })
      }
    },
    onCreditNote() {
      if(!this.in_process) {
        this.in_process = true;
        this.$loader.show();
        const d = this.$options.filters.date(this.parentParams.from_date, 'YYYY-MM-DD');
        this.$http.post(`/general-invoice/${this.branchOffice.id}/${d}/${this.params.tax}/credit-note`).then(({data}) => {
          this.$toast.success(data.message);
          this.invoiceData.invoice = '';
        }).catch(({response}) => {
          console.error((response.data.message))
          this.$toast.error(response.data.message);
        }).finally(() => {
          this.$loader.hide();
          this.in_process = false;
          this.prepareComponent();
        })
      }
    },
    onCancel() {
      if(!this.in_process) {
        this.in_process = true;
        this.$loader.show();
        const d = this.$options.filters.date(this.parentParams.from_date, 'YYYY-MM-DD');
        this.$http.post(`/general-invoice/${this.branchOffice.id}/${d}/${this.params.tax}/cancel`).then(({data}) => {
          this.$toast.success(data.message);
          this.invoiceData.invoice = '';
        }).catch(({response}) => {
          console.error((response.data.message))
          this.$toast.error(response.data.message);
        }).finally(() => {
          this.$loader.hide();
          this.in_process = false;
          this.prepareComponent();
        })
      }
    },
    paramsFix() {
      const newParams = Object.assign({},this.parentParams);
      newParams.from_date = this.$options.filters.date(this.parentParams.from_date, 'YYYY-MM-DD');
      newParams.to_date = this.$options.filters.date(this.parentParams.to_date, 'YYYY-MM-DD');
      return newParams;
    }
  }
}
</script>

<style scoped>
.lapi-iframe{
  width: 100%;
  height: 100vh;
}
</style>
