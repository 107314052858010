<template>
    <div class="activity-item">
        <div class="users">
            <div class="avatars">
                <img class="participant" :src="data.avatar" alt="">
            </div>
            <div class="meta">
                <span>{{ data.elaborate }}</span>
                <span>Orden {{ data.order_new }}</span>
                <span>Fecha: {{ data.date_closing | date('LL') }}</span>
                <span v-if="data.patient">
                    <router-link :to="{name:'patient-show', params: { id: data.patient.id }}">
                        Paciente {{ data.patient.full_name }}
                    </router-link>
                </span>
            </div>
        </div>
        <div class="details">
            <div class="amount">
                <span>Faltante/Sobrante</span>
                <span>{{ data.gap | currency('') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CashClosingListItem",
        props: {
            data: Object
        }
    }
</script>

<style scoped>

</style>