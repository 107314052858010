<template>
    <div>
        <div class="section-title section-title_app">
            <h3>Generar corte de caja</h3>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Create"
    }
</script>

<style scoped>

</style>
