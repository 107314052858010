<template>
  <div>
    <div class="section-title section-title_app">
      <h3>Corte de caja</h3>
      <router-link class="button is-success is-rounded ml-auto"
                   v-if="canCreateCashClosing"
                   :to="{name:'cash-close-create'}">
        Nuevo corte de caja
      </router-link>
    </div>
    <div class="box">
      <b-field grouped>
        <select-branch-office label="Sucursal"
            v-model="$auth.user().active_branch_office"
                              :disabled="true"
        ></select-branch-office>
        <select-date label="Fecha Inicial"
                     v-model="params.from_date"
                     @input="onInputDate"
                     :max-date="maxDate"
        ></select-date>
        <select-date label="Fecha Final"
                     :max-date="maxDate"
                     v-model="params.to_date"
                     disabled></select-date>
        <b-button @click="getFormat" class="__right">Corte de Caja</b-button>
      </b-field>
      <b-tabs type="is-toggle" expanded>
        <b-tab-item label="Reporte" v-if="false">

        </b-tab-item>

        <b-tab-item label="Depósitos">
          <DepositSection
              ref="deposit_section"
              :branch_office="params.branch_office"
              :from_date="params.from_date"
              :to_date="params.to_date"/>
        </b-tab-item>

        <b-tab-item label="Gastos" v-if="false">

        </b-tab-item>

        <b-tab-item label="Factura público general">
          <table-invoice-general
              ref="general_invoice_section"
              :parent-params="params"></table-invoice-general>
        </b-tab-item>
      </b-tabs>
    </div>
    <b-modal v-model="showDocumentModal">
      <iframe :src="urlDocument" frameborder="0" class="lapi-iframe"/>
    </b-modal>
  </div>
</template>

<script>
import OrderLoading from "../../components/order/OrderLoading";
import CashClosingListItem from "../../components/cash-closing/CashClosingListItem";
import SelectBranchOffice from "@/components/brach-office/SelectBranchOffice";
import SelectDate from "@/components/partials/SelectDate";
import TableInvoiceGeneral from "@/components/cash-closing/TableInvoiceGeneral";
import DepositSection from "@/components/cash-closing/DepositSection";
export default {
  name: "IndexCashClose",
  components: {TableInvoiceGeneral, SelectDate, SelectBranchOffice,
    CashClosingListItem, OrderLoading,
    DepositSection
  },
  data() {
    return {
      data: [],
      apiUrl: 'cash-desk-closing',
      loading: true,
      meta: {},
      params: {
        perPage: 10,
        page: 1,
        branch_office: this.$auth.user().active_branch_office,
        from_date: this.currentDate(),
        to_date: this.currentDate(),
      },
      maxDate: new Date(),
      showDocumentModal: false
    }
  },
  computed: {
    canCreateCashClosing() {
      const {actions = {}} = this.meta;
      return !!actions.can_create_cash
    },
    total() {
      return this.meta.total || 0;
    },
    urlDocument() {
      const d = this.$options.filters.date(this.params.from_date, 'YYYYMMDD');
      return `${process.env.VUE_APP_API_URL}/cash-desk-closing/document/${this.params.branch_office}/${d}`;
    }
  },
  methods: {
    getFormat() {
      this.showDocumentModal = true;
    },
    onInputDate(value){
      this.$refs.deposit_section.prepareData();
      this.$refs.general_invoice_section.prepareComponent();
    },
    currentDate(){
      const date = new Date();
      date.setHours(0);
      date.setMinutes(0);
      return date;
    }
  }
}
</script>

<style scoped>
  .__right{
    margin-left: auto;
  }

  .lapi-iframe{
    width: 100%;
    height: 100vh;
  }
</style>
